export enum PaymentStatus {
  Unsettled = 'unsettled',
  Pending = 'pending',
  Refund = 'refund',
  Settled = 'settled',
  Cancelled = 'cancelled',
  PartiallySettled = 'partially_settled',
  Initiated = 'initiated',
  Success = 'success',
  RefundInitiated = 'refund-initiated',
  Failed = 'failed',
  PartiallyPaid = 'partially_paid',
  Refunded = 'refunded',
}
